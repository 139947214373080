//screen dimensions.
$screen-mobile: 600px;

// font size and weights
$font-size-05x: 0.8rem;
$font-size-1x: 1rem;
$font-size-1-5x: 1.25rem;
$font-size-2x: 1.5rem;
$font-thin: 200;
$font-regular: 400;
$font-bold: 700;

//padding
$padding-1x : 1rem;
$padding-05x : 0.5rem;
$margin-1x : 1rem;
$margin-2x : 2rem;
$margin-05x : 0.5rem;