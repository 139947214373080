@import 'variables.scss';


html, body{
  min-height: 100%; 
 // width: 100%;
}

body {
  margin: 0rem;
  padding: 0rem;
  font-family: 'Karla', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-1x;
  font-weight: 600;
  background: linear-gradient(180deg, #80CEC8 0%, #E3F7F5 100%);
  background-repeat: no-repeat;
  background-size: cover;
  color: black;
 // letter-spacing: 0.00938em;
  @media only screen and (max-device-width: 600px){
    background: white;
  }

}

.MuiFormLabel-root {
  color: black !important
}
